import React, { useEffect, useRef, useState } from 'react'
import Figure from './Figure'
import getYouTubeId from 'get-youtube-id';
import YouTube from 'react-youtube';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import './style/overrides.css';

const serializers = {
  types: {
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    mainImage: Figure,
    youtube: ({ node }) => {
      const { url } = node;
      const id = getYouTubeId(url);
      const opts = {
        playerVars: {
          iv_load_policy: 3,
          modestbranding: 1,
          rel: 0
        }
      }
      return (<div className="YouTubeContainer"><YouTube videoId={id} opts={opts} iframeClassName='YouTubeIframe' /></div>)
    },
    bigButton: ({ node }) => {
      const { label, url } = node;
      return (
        <div className="BigButtonWrapper">
          <a className="BigButton" href={url}>{label}</a>
        </div>
      )
    },
    iframe: ({ node }) => {
      const { url } = node;
      const [loading = false, setLoading] = useState(false);

      return (
        <div className="IframeWrapper">
          {loading && <div className="IframeLoading">Loading...</div>}
          <iframe className="Iframe" style={{
            opacity: loading ? 0 : 1
          }} onLoad={() => setLoading(false)} src={url} />
        </div>
      )
    },
    photoGallery: ({ node }) => {
      const { display, images } = node;

      return (
        <div className="PhotoGallery">
          <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showArrows={true} draggable={true}>
            {
              images.map(img => (
                <img key={img._key} src={img?.asset?.url} />
                // <div key={img._key} style={{
                //   backgroundImage: `url("${img?.asset?.url}")`,
                //   minHeight: 420,
                //   backgroundSize: 'cover',
                //   backgroundPosition: 'center'
                // }}></div>
              ))
            }
          </Carousel>
        </div>)
    },
  }
}

export default serializers
